export default {
  namespaced: true,
  state: {
    categories: [],
    category: null,
  },
  actions: {
    async FETCH_CATEGORIES({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/canonical/categories/', { params });

      commit('SET_CATEGORIES', data.results);
    },
    async FETCH_CATEGORY({ commit }, categoryId) {
      const { data } = await this.$http.get(`/v1/canonical/categories/${categoryId}/`);

      commit('SET_CATEGORY', data);
    },
    async UPDATE_CATEGORY({ commit }, { categoryId, ...params }) {
      const { data } = await this.$http.patch(`/v1/canonical/categories/${categoryId}/`, params);

      commit('SET_CATEGORY', data);
    },
  },
  mutations: {
    SET_CATEGORIES(state, data) {
      state.categories = data;
    },
    SET_CATEGORY(state, data) {
      state.category = data;
    },
  },
};
