export default {
  namespaced: true,
  state: {
    attributeGroup: null,
    attributeGroups: [],
    attributeGroupsCount: null,
  },
  getters: {
    attributes: (state) => state.attributeGroup?.attributes ?? [],
  },
  actions: {
    async FETCH_ATTRIBUTE_GROUPS({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/canonical/attribute_groups/', { params, withCancelToken: true });

      commit('SET_ATTRIBUTE_GROUPS', data);
    },
    async CREATE_ATTRIBUTE_GROUP({ commit }, attributeGroup) {
      const { data } = await this.$http.post('/v1/canonical/attribute_groups/', attributeGroup);

      commit('SET_ATTRIBUTE_GROUP', data);
      return data;
    },
    async FETCH_ATTRIBUTE_GROUP({ commit }, attributeGroupId) {
      const { data } = await this.$http.get(`/v1/canonical/attribute_groups/${attributeGroupId}/`);

      commit('SET_ATTRIBUTE_GROUP', data);
    },
    async UPDATE_ATTRIBUTE_GROUP({ commit }, { attributeGroupId, params }) {
      const { data } = await this.$http.put(`/v1/canonical/attribute_groups/${attributeGroupId}/`, params);

      commit('SET_ATTRIBUTE_GROUP', data);
    },
    async DELETE_ATTRIBUTE_GROUP({ dispatch }, attributeGroupId) {
      await this.$http.delete(`/v1/canonical/attribute_groups/${attributeGroupId}/`);

      dispatch('FETCH_ATTRIBUTE_GROUPS');
    },
    async ADD_ATTRIBUTE_TO_GROUP({ dispatch }, { attributeGroupId, attributeId }) {
      await this.$http.post(`/v1/canonical/attribute_groups/${attributeGroupId}/add_attribute/${attributeId}/`);

      dispatch('FETCH_ATTRIBUTE_GROUP', attributeGroupId);
    },
    async REMOVE_ATTRIBUTE_FROM_GROUP({ dispatch }, { attributeGroupId, attributeId }) {
      await this.$http.post(`/v1/canonical/attribute_groups/${attributeGroupId}/remove_attribute/${attributeId}/`);

      dispatch('FETCH_ATTRIBUTE_GROUP', attributeGroupId);
    },
  },
  mutations: {
    SET_ATTRIBUTE_GROUPS(state, response) {
      state.attributeGroups = response.results;
      state.attributeGroupsCount = response.count;
    },
    SET_ATTRIBUTE_GROUP(state, attributeGroup) {
      state.attributeGroup = attributeGroup;
    },
  },
};
