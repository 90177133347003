import axios from 'axios';
import Vue from 'vue';
import router from '@/router';

const requestCancellation = {
  tokens: {},
  create(key) {
    this.tokens[key] = axios.CancelToken.source();

    return this.tokens[key];
  },
  cancel(key) {
    if (!(key in this.tokens)) return;

    this.tokens[key].cancel();

    delete this.tokens[key];
  },
};

const getSuitableToken = (request, store) => {
  if (request.url.includes('auth/token/refresh/')) {
    return store.state.auth.refresh;
  }
  return store.state.auth.token;
};

export const configureAxios = ({ store }) => {
  const instance = axios.create();

  const host = process.env.VUE_APP_BACKEND_URL ? process.env.VUE_APP_BACKEND_URL : '/';

  instance.defaults.baseURL = `${host}api/`;

  instance.interceptors.request.use((request) => {
    const token = getSuitableToken(request, store);
    if (token) {
      request.headers.common.Authorization = `JWT ${token}`; // append auth token to each request to the server
    }

    if (request.withCancelToken) {
      requestCancellation.cancel(request.url);
      request.cancelToken = requestCancellation.create(request.url).token;
    }

    return request;
  });

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });

    failedQueue = [];
  };

  instance.interceptors.response.use((response) => response, (error) => {
    const originalRequest = error.config;
    const status = error.response && error.response.status;

    if (status && status === 401 && !originalRequest._retry) {
      if (originalRequest.url.includes('auth/token/refresh/')) {
        store.commit('auth/RESET');
        router.push({ name: 'login' });

        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `JWT ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => {
            store.commit('auth/RESET');
            router.push({ name: 'login' });
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        store.dispatch('auth/REFRESH')
          .then((token) => {
            originalRequest.headers.Authorization = `JWT ${token}`;
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => { isRefreshing = false; });
      });
    }

    error.isAxiosError = true;
    error.isAxiosCancelError = axios.isCancel(error);
    return Promise.reject(error);
  });

  return instance;
};

export default ({ store }) => {
  const configuredAxios = configureAxios({ store });

  store.$http = configuredAxios;
  Vue.prototype.$http = configuredAxios;
};
