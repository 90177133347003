export default {
  namespaced: true,
  state: {
    canonicalVendors: [],
    canonicalVendorsCount: null,
    canonicalVendor: null,
  },
  getters: {
    vendorsForSelect: (state) => state.canonicalVendors.map((item) => item.name),
  },
  actions: {
    async FETCH_VENDORS({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/canonical/vendors/', { params, withCancelToken: true });

      commit('SET_VENDORS', data);
    },
    async FETCH_VENDOR({ commit }, vendorId) {
      const { data } = await this.$http.get(`/v1/canonical/vendors/${vendorId}/`);

      commit('SET_VENDOR', data);
    },
  },
  mutations: {
    SET_VENDORS(state, data) {
      state.canonicalVendors = data.results;
      state.canonicalVendorsCount = data.count;
    },
    SET_VENDOR(state, data) {
      state.canonicalVendor = data;
    },
  },
};
