<template>
  <div>
    <AppHeader />
    <v-container fluid>
      <slot />
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppHeader from '@/components/AppHeader/AppHeader';
import chatra from '@/helpers/chatra';

export default {
  components: {
    AppHeader,
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    user: [
      function activateChatra(user) {
        if (user) this.updateChatraUser(user);
      },
    ],
  },
  mounted() {
    if (this.user) this.updateChatraUser(this.user);
  },
  methods: {
    updateChatraUser(user) {
      window.addEventListener('load', () => {
        window.setTimeout(() => {
          chatra.setChatraUser(user);
        }, 2000);
      });
    },
  },
};
</script>
