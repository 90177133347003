<template>
  <v-snackbar v-model="show" :color="color" timeout="4000" top>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click="show = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Notify',
  data() {
    return {
      show: false,
      message: '',
      color: '',
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notify/SHOW_MESSAGE') {
        this.message = state.notify.message;
        this.color = state.notify.color;
        this.show = true;
      }
    });
  },
};
</script>
