export const filterByName = (name, array) => {
  const result = [];

  array.forEach((item) => {
    if (item.submenu && item.submenu.length) {
      const filterResult = filterByName(name, item.submenu);

      if (!filterResult.length) return;

      return result.push({
        ...item,
        submenu: filterResult,
      });
    }

    if (item.name.toLowerCase().includes(name.toLowerCase())) {
      result.push(item);
    }
  });

  return result;
};

export const createCategoriesChain = (id, categories) => {
  const result = [];

  categories.forEach((category, index) => {
    const hasCategory = categories.find((item) => item.id === id);
    const hasCategoryIndex = categories.findIndex((item) => item.id === id);
    const hasSubmenu = category.submenu && category.submenu.length;

    if (hasCategory) {
      return result.push({
        index: hasCategoryIndex,
        category: hasCategory,
      });
    }

    if (hasSubmenu) {
      const searchResult = createCategoriesChain(id, category.submenu);

      if (!searchResult.length) return;

      result.push({
        index,
        category: {
          ...category,
          submenu: searchResult,
        },
      });
    }
  });

  return result;
};

export const createChunks = (categories) => {
  const result = [];
  const [category = null] = categories;

  (function getChunk(item) {
    const hasSubmenu = item.category && item.category.submenu && item.category.submenu.length;

    result.push(item);

    if (hasSubmenu) {
      const [submenu] = item.category.submenu;

      getChunk(submenu);
    }
  }(category));

  return result;
};

export const getCategoriesIndexes = (categories) => categories
  .filter((item) => item.category && item.category.submenu)
  .map(({ index }) => index);

export const getCategoriesFromIndexes = (indexes, categories) => {
  const result = [];

  (function getCategory(items) {
    const [index] = indexes;
    const category = items[index];
    const hasSubmenu = category.submenu && category.submenu.length;

    result.push(items[index]);
    indexes.shift();

    if (hasSubmenu && indexes.length) getCategory(category.submenu);
  }(categories));

  return result;
};
