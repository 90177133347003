import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import {
  Vue as VueIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  Dedupe as DedupeIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { release } from '@/build.env';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release,
    dsn: 'https://b0d7ea7768964676ae2ba2c48b97a942@o68989.ingest.sentry.io/5473734',
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        attachProps: true,
        logErrors: true,
        trackComponents: true,
      }),
      new ExtraErrorDataIntegration(),
      new DedupeIntegration(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });

  window.sentry = Sentry;
}
