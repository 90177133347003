export default {
  namespaced: true,
  state: {
    jobs: [],
    jobsCount: 0,
    job: {},
    jobErrors: [],
    jobErrorsCount: 0,
  },
  getters: {
    jobsIdsToGet: (state) => state.jobs.filter((j) => !j.finished).map((j) => j.id).join(','),
  },
  actions: {
    async FETCH_JOBS({ commit }, params = {}) {
      const { data } = await this.$http.get('v1/operations/jobs/', { params });

      commit('SET_JOBS', data);
      commit('SET_COUNT', data);
    },
    async UPDATE_JOBS({ commit, getters }) {
      const { data } = await this.$http.get('v1/operations/jobs/', { params: { ids: getters.jobsIdsToGet } });

      commit('UPDATE_JOBS', data);
    },
    async FETCH_JOB({ commit }, jobId) {
      const { data } = await this.$http.get(`v1/operations/jobs/${jobId}`);

      commit('SET_JOB', data);
    },
    async CREATE_JOB({ state, commit }, job) {
      const { data } = await this.$http.post('/v1/operations/jobs/', job);
      data.count = state.jobsCount + 1;
      commit('SET_JOB', data);
      commit('ADD_JOB', data);
      commit('SET_COUNT', data);
    },
    async STOP_JOB({ commit }, jobId) {
      const { data } = await this.$http.post(`/v1/operations/jobs/${jobId}/stop/`);
      if (data.affected_jobs) commit('SET_JOB', data.affected_jobs[0]);
    },
    async FETCH_JOB_ERRORS({ commit }, { jobId, params }) {
      const { data } = await this.$http.get(`v1/operations/jobs/${jobId}/errors/`, { params });

      commit('SET_ERRORS', data);
    },
  },
  mutations: {
    SET_JOBS(state, data) {
      state.jobs = data.results;
    },
    UPDATE_JOBS(state, data) {
      data.results.forEach((job) => {
        const jobIndex = state.jobs.findIndex((j) => j.id === job.id);
        state.jobs.splice(jobIndex, 1, job);
      });
    },
    SET_COUNT(state, data) {
      state.jobsCount = data.count;
    },
    SET_JOB(state, data) {
      const jobIndex = state.jobs.findIndex((j) => j.id === data.id);
      state.jobs.splice(jobIndex, 1, data);
      state.job = data;
    },
    ADD_JOB(state, data) {
      state.jobs.pop(state.jobs.length - 1);
      state.jobs.unshift(data);
    },
    SET_ERRORS(state, data) {
      state.jobErrors = data.results;
      state.jobErrorsCount = data.count;
    },
  },
};
