/*
 * Nuxt.js-like vuex modules
 *
 * For details, check out https://webpack.js.org/guides/dependency-management/#context-module-api
 *
 */

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';

import { filenameToStoreModuleName } from '@/helpers/store';

Vue.use(Vuex);

const modules = {};

function importAll(r) {
  r.keys().forEach((key) => {
    modules[filenameToStoreModuleName(key)] = r(key).default;
  });
}

importAll(require.context('./store/', false, /\.js$/));

export default new Vuex.Store({
  modules,
  strict: true,
  plugins: [
    createPersistedState({
      paths: [
        'auth.token',
        'auth.user',
        'auth.refresh',
      ],
    }),
    createMutationsSharer({
      predicate: [
        'auth/SET_TOKEN',
        'auth/SET_REFRESH_TOKEN',
      ],
    }),
  ],
});
