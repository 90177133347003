import Vue from 'vue';
import VueGtm from 'vue-gtm';

import router from '@/router';

Vue.use(VueGtm, {
  id: 'GTM-TQSC2W9',
  enabled: process.env.NODE_ENV === 'production',
  vueRouter: router,
});
