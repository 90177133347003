export default {
  namespaced: true,
  state: {
    message: '',
    color: '',
  },
  mutations: {
    SHOW_MESSAGE(state, { message, color }) {
      state.message = message;
      state.color = color;
    },
  },
};
