import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    product: null,
    products: [],
    products_count: null,
    manualRetailPrices: [],
  },
  getters: {
    freeRegionsForManualRetailPrices: (state, getters, rootState) => {
      const takenRegions = state.manualRetailPrices.map((item) => item.region);

      return rootState.reference.regions.filter((region) => !takenRegions.includes(region.id));
    },
  },
  actions: {
    async FETCH_PRODUCTS({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/canonical/products/', { params, withCancelToken: true });

      commit('SET_PRODUCTS', data);
    },
    async FETCH_PRODUCT({ commit }, productId) {
      const { data } = await this.$http.get(`/v1/canonical/products/${productId}/`);

      commit('SET_PRODUCT', data);
    },
    async SET_AUTOSYNC({ dispatch }, productId) {
      await this.$http.post(`/v1/canonical/products/${productId}/set_autosync/`);
      await dispatch('FETCH_PRODUCT', productId);
    },
    async UPDATE_PRODUCT({ commit }, { productId, params }) {
      const { data } = await this.$http.put(`/v1/canonical/products/${productId}/`, params);

      commit('SET_PRODUCT', data);
    },
    async ARCHIVE_PRODUCT({ commit }, productId) {
      const { data } = await this.$http.post(`/v1/canonical/products/${productId}/archive/`);

      commit('SET_PRODUCT', data.affected[0]);
    },
    async UNARCHIVE_PRODUCT({ commit }, productId) {
      const { data } = await this.$http.post(`/v1/canonical/products/${productId}/unarchive/`);

      commit('SET_PRODUCT', data.affected[0]);
    },
    async SET_ATTRIBUTE_VALUES({ commit }, { productId, params }) {
      const { data } = await this.$http.post(`/v1/canonical/products/${productId}/set_attribute_values/`, params);

      commit('SET_PRODUCT', data.affected[0]);
    },
    async MERGE_PRODUCTS({ commit }, { productId, itemId }) {
      const { data } = await this.$http.post(`/v1/canonical/products/${productId}/merge_with/${itemId}/`);

      commit('SET_PRODUCT', data.affected[0]);
      return data.affected;
    },
    async FETCH_MANUAL_RETAIL_PRICES({ commit }, productId) {
      const { data } = await this.$http.get(`/v1/canonical/products/${productId}/manual_retail_prices/`);

      commit('SET_MANUAL_RETAIL_PRICES', data);
    },
    async CREATE_MANUAL_RETAIL_PRICE({ commit }, { productId, data }) {
      const response = await this.$http.post(`/v1/canonical/products/${productId}/manual_retail_prices/`, data);

      commit('UPDATE_MANUAL_RETAIL_PRICE_BY_REGION', response.data);
    },
    async UPDATE_MANUAL_RETAIL_PRICE({ commit }, { productId, manualRetailPriceId, ...data }) {
      const response = await this.$http.patch(`/v1/canonical/products/${productId}/manual_retail_prices/${manualRetailPriceId}/`, data);

      commit('UPDATE_MANUAL_RETAIL_PRICE_BY_REGION', response.data);
    },
    async DELETE_MANUAL_RETAIL_PRICE({ commit }, { productId, manualRetailPriceId }) {
      await this.$http.delete(`/v1/canonical/products/${productId}/manual_retail_prices/${manualRetailPriceId}/`);

      commit('REMOVE_MANUAL_RETAIL_PRICE_BY_ID', manualRetailPriceId);
    },
  },
  mutations: {
    SET_PRODUCTS(state, response) {
      state.products = response.results;
      state.products_count = response.count;
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_PAGE(state, value) {
      state.page = value;
    },
    SET_MANUAL_RETAIL_PRICES(state, data) {
      state.manualRetailPrices = data;
    },
    ADD_MANUAL_RETAIL_PRICE(state, data) {
      state.manualRetailPrices = [...state.manualRetailPrices, data];
    },
    UPDATE_MANUAL_RETAIL_PRICE_BY_REGION(state, data) {
      const index = state.manualRetailPrices.findIndex(({ region }) => region === data.region);

      Vue.set(state.manualRetailPrices, index, data);
    },
    UPDATE_MANUAL_RETAIL_PRICE_BY_INDEX(state, { index, ...data }) {
      Vue.set(state.manualRetailPrices, index, data);
    },
    REMOVE_MANUAL_RETAIL_PRICE(state, regionId) {
      const index = state.manualRetailPrices.findIndex(({ region }) => region === regionId);

      Vue.delete(state.manualRetailPrices, index);
    },
    REMOVE_MANUAL_RETAIL_PRICE_BY_ID(state, id) {
      const index = state.manualRetailPrices.findIndex((item) => item.id === id);

      Vue.delete(state.manualRetailPrices, index);
    },
  },
};
