export default {
  namespaced: true,
  state: {
    vendors: [],
    vendorsCount: null,
    vendor: null,
    selectedVendorsItems: [],
  },
  getters: {
    selectedVendorsObjects: (state) => state.vendors.filter((item) => state.selectedVendorsItems.includes(item.id)),
  },
  actions: {
    async FETCH_VENDORS({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/inventory/vendors/', { params, withCancelToken: true });
      commit('SET_VENDORS', data);
    },
    async BIND_VENDORS({ commit }, toSend) {
      const { data } = await this.$http.post('/v1/inventory/mass_inventory_vendor_actions/bind_with_canonical/', toSend);

      commit('UPDATE_VENDORS_FROM_AFFECTED_ITEMS', data.affected_vendors);

      return data.affected_vendors;
    },
    async RENAME_VENDOR({ commit }, { vendorId, newName }) {
      const { data } = await this.$http.post(`/v1/inventory/vendors/${vendorId}/rename_canonical/`, { new_canonical_vendor_name: newName });
      commit('UPDATE_VENDORS_FROM_AFFECTED_ITEMS', data.affected_vendors);
    },
  },
  mutations: {
    SET_VENDORS(state, vendors) {
      state.vendors = vendors.results;
      state.vendorsCount = vendors.count;
    },
    UPDATE_VENDORS_FROM_AFFECTED_ITEMS(state, affected) {
      affected.forEach((affectedItem) => {
        const index = state.vendors.findIndex(({ id }) => affectedItem.id === id);
        state.vendors.splice(index, 1, affectedItem);
      });
    },
    SET_SELECTED_VENDORS_ITEMS(state, data) {
      state.selectedVendorsItems = data;
    },
    TOGGLE_SELECTED_VENDORS_ITEMS(state, itemId) {
      const itemExistsInList = state.selectedVendorsItems.includes(itemId);

      if (itemExistsInList) {
        state.selectedVendorsItems = state.selectedVendorsItems.filter((id) => id !== itemId);
      } else {
        state.selectedVendorsItems = [...state.selectedVendorsItems, itemId];
      }
    },
  },
};
