import { configureAxios } from '@/plugins/03_axios';

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    refresh: null,
  },
  getters: {
    isLoggedIn: (state) => Boolean(state.user && state.token),
    isSuperuser: (state) => state.user.is_superuser,
  },
  actions: {
    async GET_WHOAMI({ commit }) {
      const response = await this.$http.get('v1/auth/whoami/');

      commit('SET_USER', response.data);
    },
    async LOG_IN({ commit, dispatch }, authData) {
      const response = await this.$http.post('v1/auth/token/', authData);

      commit('SET_TOKEN', response.data.access);
      commit('SET_REFRESH_TOKEN', response.data.refresh);

      await dispatch('GET_WHOAMI');
    },
    async REFRESH({ commit, state }) {
      /**
       * We have to initialize axios locally cuz authentication middleware initialized before vue.
       */
      const axios = configureAxios({ store: this });
      const response = await axios.post('v1/auth/token/refresh/', { refresh: state.refresh });
      commit('SET_TOKEN', response.data.access);
      commit('SET_REFRESH_TOKEN', response.data.refresh);
      return response.data.access;
    },
  },
  mutations: {
    RESET: (state) => {
      state.user = null;
      state.token = null;
      state.refresh = null;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_REFRESH_TOKEN: (state, token) => {
      state.refresh = token;
    },
  },
};
