<template>
  <v-app>
    <v-main>
      <Notify />
      <DialogConfirm ref="confirm" />
      <component :is="layout">
        <router-view />
      </component>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import SentryConfig from '@/helpers/sentry';

import Notify from '@/components/common/Notify';
import DialogConfirm from '@/components/common/DialogConfirm';

export default {
  components: {
    Notify,
    DialogConfirm,
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isLoggedIn']),
    layout() {
      const layout = this.$route.meta.layout || 'default';
      return `${layout}-layout`;
    },
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      async handler(val) {
        if (!val) return;
        this.GET_WHOAMI();
        SentryConfig.setUser(this.user);
      },
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
  methods: {
    ...mapActions('auth', ['GET_WHOAMI']),
  },
};
</script>
