const isProduction = process.env.NODE_ENV === 'production';

export default {
  setUser(user) {
    if (!isProduction) return;

    window.sentry.setUser({
      id: user.id,
      username: user.username,
      email: user.email,
      ip_address: '{{ auto }}',
    });
  },
  clearUser() {
    if (!isProduction) return;

    window.sentry.configureScope((scope) => scope.setUser(null));
  },
};
