export default {
  namespaced: true,
  state: {
    attributes: [],
  },
  getters: {
    orderedAttributes: (state) => [...state.attributes].sort((attribute) => (attribute.required ? -1 : 1)),
  },
  actions: {
    async FETCH_ATTRIBUTES({ commit }, params) {
      const { data } = await this.$http.get('/v1/marketplace/attributes/', { params: { ...params, page_size: 100500 }, withCancelToken: true });

      commit('SET_ATTRIBUTES', data);
    },
    async UPDATE_ATTRIBUTES(_, { listingId, itemId, data }) {
      await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/set_attribute_values/`, data);
    },
  },
  mutations: {
    SET_ATTRIBUTES(state, data) {
      state.attributes = data.results;
    },
  },
};
