import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    meta: { public: false },
    component: () => import('@/views/Warehouses'),
    name: 'main',
  },
  {
    path: '/search/',
    meta: { public: false },
    component: () => import('@/views/Search'),
    name: 'search',
  },
  {
    path: '/warehouses/:warehouseId/',
    meta: { public: false },
    component: () => import('@/views/WarehouseItems'),
    name: 'WarehouseItems',
  },
  {
    path: '/items/:productId/',
    meta: { public: false },
    component: () => import('@/views/Item'),
  },
  {
    path: '/products/:productId/',
    meta: { public: false },
    component: () => import('@/views/Product'),
  },
  {
    path: '/feeds/',
    meta: { public: false },
    component: () => import('@/views/Feeds'),
  },
  {
    path: '/feeds/:feedId/',
    meta: { public: false },
    component: () => import('@/views/Feed'),
  },
  {
    path: '/feeds/:feedId/:itemId/',
    meta: { public: false },
    component: () => import('@/views/FeedItem'),
  },
  {
    path: '/categories/:categoryId/',
    meta: { public: false },
    component: () => import('@/views/Category'),
  },
  {
    path: '/infomodels/',
    meta: { public: false },
    component: () => import('@/views/Infomodels'),
  },
  {
    path: '/infomodels/:attributeGroupId/',
    meta: { public: false },
    component: () => import('@/views/Infomodel'),
  },
  {
    path: '/attributes/',
    meta: { public: false },
    component: () => import('@/views/InfomodelsAttributes'),
  },
  {
    path: '/brands/',
    meta: { public: false },
    component: () => import('@/views/Brands'),
  },
  {
    path: '/operations/',
    meta: { public: false },
    component: () => import('@/views/Operations'),
  },
  {
    path: '/operations/:jobId/log',
    meta: { public: false },
    component: () => import('@/views/OperationsLog'),
  },
  {
    path: '/signin',
    meta: {
      layout: 'empty',
      public: true,
      action: 'auth',
    },
    component: () => import('@/views/Signin'),
    name: 'login',
  },
  { // Always leave this as last one
    path: '*',
    redirect: { name: 'main' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
