export default {
  namespaced: true,
  state: {
    attribute: {},
    attributes: [],
    attributesCount: null,
  },
  actions: {
    async FETCH_ATTRIBUTES({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/canonical/attributes/', { params });

      commit('SET_ATTRIBUTES', data);
    },
    async CREATE_ATTRIBUTE({ commit, dispatch }, attribute) {
      const { data } = await this.$http.post('/v1/canonical/attributes/', attribute);

      commit('SET_ATTRIBUTE', data);
      dispatch('FETCH_ATTRIBUTES');
      return data;
    },
    async FETCH_ATTRIBUTE({ commit }, attributeId) {
      const { data } = await this.$http.get(`/v1/canonical/attributes/${attributeId}`);

      commit('SET_ATTRIBUTE', data);
    },
    async SEARCH_ATTRIBUTE({ }, autocomplete) {
      const { data } = await this.$http.get('/v1/canonical/attributes/', { params: { autocomplete } });

      return data.results;
    },
    async UPDATE_ATTRIBUTE({ commit }, { attributeId, params }) {
      const { data } = await this.$http.patch(`/v1/canonical/attributes/${attributeId}/`, params);

      commit('SET_ATTRIBUTE', data);
      commit('UPDATE_ATTRIBUTE', { attributeId, data });
    },
    async DELETE_ATTRIBUTE({ commit }, attributeId) {
      await this.$http.delete(`/v1/canonical/attributes/${attributeId}`);
      commit('DELETE_ATTRIBUTE', attributeId);
    },
  },
  mutations: {
    SET_ATTRIBUTES(state, response) {
      state.attributes = response.results;
      state.attributesCount = response.count;
    },
    SET_ATTRIBUTE(state, attribute) {
      state.attribute = attribute;
    },
    UPDATE_ATTRIBUTE(state, { attributeId, data }) {
      const indexToDelete = state.attributes.findIndex((a) => a.id === attributeId);
      state.attributes.splice(indexToDelete, 1, data);
    },
    DELETE_ATTRIBUTE(state, attributeId) {
      const indexToDelete = state.attributes.findIndex((a) => a.id === attributeId);
      state.attributes.splice(indexToDelete);
    },
  },
};
