import Vue from 'vue';
import get from 'lodash.get';

import {
  filterByName,
  createCategoriesChain,
  createChunks,
  getCategoriesIndexes,
  getCategoriesFromIndexes,
} from '@/helpers/categories';

export default {
  namespaced: true,
  state: {
    listings: [],
    listing: {},
    listings_count: null,
    items: [],
    items_count: null,
    item: {},
    feedCategories: [],
    selectedFeedItems: [],
    marketplaces: [],
  },
  getters: {
    listingId: (state) => get(state.listing, 'id'),
    marketplaceSlug: (state) => get(state.listing, 'marketplace.slug'),
    firstLevelCategories: (state) => state.feedCategories.filter((category) => category.level === 0),
    getCategoriesByParentId: (state, getters) => (categoryId) => state.feedCategories.filter((category) => category.parent_id === categoryId).map((category) => ({
      ...category,
      submenu: getters.getCategoriesByParentId(category.id),
    })),
    getCategories: (state, getters) => getters.firstLevelCategories.map((category) => ({
      ...category,
      submenu: getters.getCategoriesByParentId(category.id),
    })),
    filterCategoriesByName: (state, getters) => (name) => filterByName(name, getters.getCategories),
    getSelectedCategories: (state, getters) => (id) => {
      const categoriesChain = createCategoriesChain(id, getters.getCategories);
      const categoriesChunks = createChunks(categoriesChain);
      const categoriesIndexes = getCategoriesIndexes(categoriesChunks);
      const categories = getCategoriesFromIndexes(categoriesIndexes, getters.getCategories);

      return categories;
    },
  },
  actions: {
    async FETCH_LISTINGS({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/marketplace/listings/', { params });
      commit('SET_LISTINGS', data);
    },
    async FETCH_LISTING({ commit }, listingId) {
      const { data } = await this.$http.get(`/v1/marketplace/listings/${listingId}/`);
      commit('SET_LISTING', data);
    },
    async FETCH_ITEMS({ commit }, { listingId, params = {} }) {
      const { data } = await this.$http.get(`/v1/marketplace/listings/${listingId}/items/`, { params, withCancelToken: true });
      commit('SET_ITEMS', data);
    },
    async FETCH_ITEM_DETAILS({ commit }, { listingId, itemId }) {
      const { data } = await this.$http.get(`/v1/marketplace/listings/${listingId}/items/${itemId}/`, { withCancelToken: true });
      commit('SET_ITEM_DETAILS', data);
    },
    async FETCH_MARKETPLACES({ commit }) {
      const { data } = await this.$http.get('/v1/marketplace/marketplaces/');

      commit('SET_MARKETPLACES', data.results);
    },
    async FETCH_FEED_CATEGORIES({ commit }, categorySlug) {
      const { data } = await this.$http.get(`/v1/marketplace/marketplaces/${categorySlug}/categories/`);

      commit('SET_FEED_CATEGORIES', data);
    },
    async SET_CATEGORY_FOR_FEED_ITEM({ commit }, { listingId, itemId, categoryId }) {
      const { data } = await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/change_category_to/${categoryId}/`);

      commit('SET_ITEM', data.affected_items[0]);
    },
    async DROP_CATEGORY_FOR_FEED_ITEM({ commit }, { listingId, itemId }) {
      const { data } = await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/drop_category/`);

      commit('SET_ITEM', data.affected_items[0]);
    },
    async BULK_SET_CATEGORY_FOR_FEED_ITEMS({ commit }, { listingId, categoryId, items }) {
      const { data } = await this.$http.post(`/v1/marketplace/listings/${listingId}/items/change_category_to/${categoryId}/`, {
        items,
      });

      data.affected_items.forEach((item) => {
        commit('SET_ITEM', item);
      });
    },
    async BULK_DROP_CATEGORY_FOR_FEED_ITEMS({}, { listingId, items }) {
      await this.$http.post(`/v1/marketplace/listings/${listingId}/items/drop_category/`, {
        items,
      });
    },
    async DELETE_FEED_ITEM({ commit }, { listingId, itemId }) {
      await this.$http.delete(`/v1/marketplace/listings/${listingId}/items/${itemId}/`);

      commit('REMOVE_ITEM', itemId);
    },
    async ACTIVATE_FEED_ITEM({ commit, state }, { listingId, itemId }) {
      if (state.items.find((item) => item.id === itemId).inactive_listings.includes(listingId)) {
        await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/activate/`);

        commit('ACTIVATE_ITEM', itemId);
      }
    },
    async DEACTIVATE_FEED_ITEM({ commit, state }, { listingId, itemId }) {
      if (!state.items.find((item) => item.id === itemId).inactive_listings.includes(listingId)) {
        await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/deactivate/`);

        commit('DEACTIVATE_ITEM', itemId);
      }
    },
    async SET_FEED_ITEM_STATUS({ commit }, { listingId, itemId, status }) {
      const { data } = await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/change_status_to/${status}/`);

      commit('SET_ITEM', data.affected_items[0]);
    },
    async SET_FEED_ITEM_EXTERNAL_ID({ commit }, { listingId, itemId, externalId }) {
      const { data } = await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/change_status_to/confirmed/`, {
        external_id: externalId,
      });

      commit('SET_ITEM', data.affected_items[0]);
    },
    async DESTROY_FEED_ITEM({ commit }, { listingId, itemId }) {
      await this.$http.post(`/v1/marketplace/listings/${listingId}/items/${itemId}/full_delete/`);

      commit('REMOVE_ITEM', itemId);
    },
  },
  mutations: {
    SET_LISTINGS(state, response) {
      state.listings = response.results;
      state.listings_count = response.count;
    },
    SET_LISTING(state, listing) {
      state.listing = listing;
    },
    SET_ITEMS(state, response) {
      state.items = response.results;
      state.items_count = response.count;
    },
    SET_ITEM(state, data) {
      const index = state.items.findIndex(({ id }) => data.id === id);

      if (typeof index !== 'number') return;

      Vue.set(state.items, index, data);
    },
    SET_ITEM_DETAILS(state, data) {
      state.item = data;
    },
    REMOVE_ITEM(state, itemId) {
      const index = state.items.findIndex(({ id }) => itemId === id);

      Vue.delete(state.items, index);
    },
    ACTIVATE_ITEM(state, itemId) {
      const item = state.items.find((obj) => obj.id === itemId);
      item.inactive_listings = item.inactive_listings.filter((id) => id !== state.listing.id);
    },
    DEACTIVATE_ITEM(state, itemId) {
      state.items.find((item) => item.id === itemId).inactive_listings.push(state.listing.id);
    },
    SET_FEED_CATEGORIES(state, data) {
      state.feedCategories = data;
    },
    SET_SELECTED_FEED_ITEMS(state, data) {
      state.selectedFeedItems = data;
    },
    TOGGLE_SELECTED_FEED_ITEMS(state, itemId) {
      const itemExistsInList = state.selectedFeedItems.includes(itemId);

      if (itemExistsInList) {
        state.selectedFeedItems = state.selectedFeedItems.filter((id) => id !== itemId);
      } else {
        state.selectedFeedItems = [...state.selectedFeedItems, itemId];
      }
    },
    SET_MARKETPLACES(state, data) {
      state.marketplaces = data;
    },
  },
};
