export default {
  namespaced: true,
  state() {
    return {
      component: null,
      context: {},
    };
  },
  mutations: {
    SHOW_MODAL(state, data) {
      state.component = data.component || data;
      state.context = data.context || {};
    },
    HIDE_MODAL(state) {
      state.component = null;
      state.context = {};
    },
  },
};
