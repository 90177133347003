import Vue from 'vue';
import App from '@/App';

import router from '@/router';
import store from '@/store';
import auth from '@/modules/auth';

import loadPlugins from '@/services/loadPlugins';

import vuetify from '@/modules/vuetify';

Vue.config.productionTip = false;

auth({ router, store }); // have to do this before Vue initialisation, https://github.com/vuejs/vue-router/issues/748

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

loadPlugins({ app, store, router });

app.$mount('#app');
