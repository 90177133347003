<template>
  <v-app-bar class="app-header" elevation="0">
    <v-container fluid>
      <v-row align="center">
        <v-col cols="auto">
          <router-link class="app-header__title" to="/">
            <v-toolbar-title>Товары</v-toolbar-title>
          </router-link>
        </v-col>
        <v-col cols="auto">
          <router-link class="app-header__title" to="/search">
            <v-toolbar-title>Поиск</v-toolbar-title>
          </router-link>
        </v-col>
        <v-col cols="auto">
          <router-link class="app-header__title" to="/feeds/">
            <v-toolbar-title>Листинги</v-toolbar-title>
          </router-link>
        </v-col>
        <v-col cols="auto">
          <router-link class="app-header__title" to="/infomodels/">
            <v-toolbar-title>Инфомодели</v-toolbar-title>
          </router-link>
        </v-col>
        <v-col cols="auto">
          <router-link class="app-header__title" to="/brands/">
            <v-toolbar-title>Бренды</v-toolbar-title>
          </router-link>
        </v-col>
        <v-col cols="auto">
          <router-link class="app-header__title" to="/operations/">
            <v-toolbar-title>Массовые операции</v-toolbar-title>
          </router-link>
        </v-col>
        <v-col class="ml-auto" cols="auto">
          <v-btn @click="logout" icon>
            <v-icon>mdi-exit-run</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapMutations } from 'vuex';

import SentryConfig from '@/helpers/sentry';

export default {
  methods: {
    ...mapMutations('auth', ['RESET']),
    logout() {
      this.RESET();
      this.$router.push('/signin');
      SentryConfig.clearUser();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  &__title {
    cursor: pointer;
    text-decoration: none;
    color: hsl(0, 0%, 0%);

    &.router-link-exact-active {
      color: var(--v-primary-base);
    }
  }
}
</style>
