export default {
  namespaced: true,
  state: {
    owners: [],
    owner: {},
    categories: [],
    vendors: [],
    units: {},
    warehouses: [],
    regions: [],
  },
  getters: {
    ownersForSelect: (state) => state.owners.map((item) => ({ text: item.name, value: item.slug })),
    regionsForSelect: (state) => state.regions.map((item) => ({ text: item.name, value: item.slug })),
    categoriesForSelect: (state) => state.categories.map((item) => ({ text: item.name, value: item.id })),
    vendorsForSelect: (state) => state.vendors.map((item) => ({ text: item.name, value: item.id })),
    unitForSelect: (state) => Object.entries(state.units).map(([value, text]) => ({ text, value })),
    getWarehouseRegionName: (state) => (id) => state.warehouses.find((item) => item.id === id)?.region.name ?? '–',
    getWarehouseRegionId: (state) => (id) => state.warehouses.find((item) => item.id === id)?.region.id,
    getRegionById: (state) => (id) => state.regions.find((item) => item.id === id),
    getRegionBySlug: (state) => (slug) => state.regions.find((item) => item.slug === slug),
    getRegionNameBySlug: (state) => (slug) => state.regions.find((item) => item.slug === slug)?.name,
    getOwnerNameBySlug: (state) => (slug) => state.owners.find((item) => item.slug === slug)?.name,
  },
  actions: {
    async FETCH_OWNERS({ commit }, params = { page_size: 100500 }) {
      const { data } = await this.$http.get('/v1/inventory/owners/', { params });
      commit('SET_OWNERS', data.results);
    },
    async FETCH_CATEGORIES({ commit }, ownerSlug) {
      const { data } = await this.$http.get('/v1/inventory/categories/', { params: { page_size: 100500, owner__slug: ownerSlug } });
      commit('SET_CATEGORIES', data.results);
    },
    async FETCH_VENDORS({ commit }, ownerSlug) {
      const { data } = await this.$http.get('/v1/inventory/vendors/', { params: { page_size: 100500, owner__slug: ownerSlug } });

      commit('SET_VENDORS', data.results);
    },
    async FETCH_UNITS({ commit, state }) {
      if (Object.values(state.units).length) return;

      const { data } = await this.$http.get('/v1/references/units/');

      commit('SET_UNITS', data);
    },
    async FETCH_WAREHOUSES({ commit }) {
      const { data } = await this.$http.get('/v1/references/warehouses/');

      commit('SET_WAREHOUSES', data);
    },
    async FETCH_REGIONS({ state, commit }) {
      if (Object.keys(state.regions).length) return;

      const { data } = await this.$http.get('/v1/references/regions/');

      commit('SET_REGIONS', data);
    },
  },
  mutations: {
    SET_OWNERS(state, owners) {
      state.owners = owners;
    },
    SET_OWNER(state, owner) {
      state.owner = owner;
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_VENDORS(state, vendors) {
      state.vendors = vendors;
    },
    SET_UNITS(state, units) {
      state.units = units;
    },
    SET_WAREHOUSES(state, warehouses) {
      state.warehouses = warehouses;
    },
    SET_REGIONS(state, data) {
      state.regions = data;
    },
  },
};
