export default {
  namespaced: true,
  state: {
    product: null,
    grouped_items: [],
  },
  getters: {
    groupedItemsByOwners: (state) => {
      if (!state.grouped_items.length) return null;

      const grouped = {};

      state.grouped_items.forEach((item) => {
        if (!(item.owner.name in grouped)) {
          grouped[item.owner.name] = [];
        }

        grouped[item.owner.name].push(item);
      });

      if (Object.keys(grouped).length === 0) return null;

      return grouped;
    },
    doesTheProductInListing: (state) => (listingId) => state.product?.listings?.some((listing) => listingId === listing.id) ?? false,
    selectedListings: (state) => state.product?.listings?.map((l) => l.id) ?? [],
    renderOffersGroups: (state) => state.product?.owner?.slug !== 'leroy-kazahstan' ?? true,
  },
  actions: {
    async FETCH_PRODUCT({ commit }, productId) {
      const { data } = await this.$http.get(`/v1/inventory/items/${productId}/`);
      commit('SET_PRODUCT', data);
    },
    async FETCH_GROUPED_ITEMS({ commit }, params = {}) {
      const { data } = await this.$http.get('/v1/inventory/grouped_items/', {
        params: {
          ...!params.linked_product ? params : { linked_product: params.linked_product },
        },
        withCancelToken: true,
      });

      commit('SET_GROUPED_ITEMS', data);
    },
    async BIND_PRODUCT({ commit, dispatch }, { bindToId, bindId }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${bindId}/bind_with/${bindToId}/`);
      commit('UPDATE_GROUPED_ITEMS', data.affected_items);
      commit('UPDATE_PRODUCT_FROM_AFFECTED_ITEMS', data.affected_items);
      dispatch('canonical_products/FETCH_PRODUCT', data.affected_items[0].gmid, { root: true });
      return data.affected_items;
    },
    async UNBIND_PRODUCT({ commit }, { bindToId, bindId }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${bindId}/unbind_from/${bindToId}/`);
      commit('UPDATE_GROUPED_ITEMS', data.affected_items);
      commit('UPDATE_PRODUCT_FROM_AFFECTED_ITEMS', data.affected_items);
      return data.affected_items;
    },
    async MERGE_WITH_ITEM({ commit }, { bindToId, bindId }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${bindId}/merge_with/${bindToId}/`);
      commit('UPDATE_GROUPED_ITEMS', data.affected_items);
      commit('UPDATE_PRODUCT_FROM_AFFECTED_ITEMS', data.affected_items);
      return data.affected_items;
    },
    async BIND_PRODUCT_MASS({}, itemIds) {
      const { data } = await this.$http.post('/v1/inventory/mass_item_actions/bind_with/new/', { item_ids: itemIds });
      return data.message;
    },
    async ADD_TO_LISTING({ dispatch }, { itemId, listingId }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${itemId}/add_to_listing/${listingId}/`);
      await dispatch('FETCH_PRODUCT', itemId);
      return data.affected_items;
    },
    async ADD_TO_LISTING_MASS({}, { itemIds, listingId }) {
      const { data } = await this.$http.post('/v1/inventory/mass_item_actions/add_to_listing/', { item_ids: itemIds, listing_id: listingId });
      return data.message;
    },
    async REMOVE_ITEMS_MASS({}, { ownerId, itemIds }) {
      const { data } = await this.$http.post('/v1/inventory/mass_item_actions/remove_by_owner/', { item_ids: itemIds, owner_id: ownerId });
      return data.message;
    },
    async REMOVE_FROM_LISTING({ dispatch }, { itemId, listingId }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${itemId}/remove_from_listing/${listingId}/`);
      await dispatch('FETCH_PRODUCT', itemId);
      return data.affected_items;
    },
    async BIND_PRODUCT_TO_CANONICAL({ commit }, { productId, itemId }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${itemId}/bind_with/product/${productId}/`);
      commit('UPDATE_GROUPED_ITEMS', data.affected_items);
      commit('UPDATE_PRODUCT_FROM_AFFECTED_ITEMS', data.affected_items);
      return data.affected_items;
    },
    async UNBIND_PRODUCT_FROM_CANONICAL({ commit }, { productId, itemId }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${itemId}/unbind_from_product/${productId}/`);
      commit('UPDATE_GROUPED_ITEMS', data.affected_items);
      commit('UPDATE_PRODUCT_FROM_AFFECTED_ITEMS', data.affected_items);
      return data.affected_items;
    },
    async SET_PRODUCT_UNIT_FACTOR({}, { itemId, unitFactor }) {
      const { data } = await this.$http.post(`/v1/inventory/items/${itemId}/set_unit_factor/`, { unit_factor: unitFactor });
      return data.affected_items;
    },
  },
  mutations: {
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    UPDATE_PRODUCT_FROM_AFFECTED_ITEMS(state, data) {
      if (!state.product) return;
      const currentItem = data.find(({ id }) => state.product.id === id);
      if (currentItem) {
        state.product = currentItem;
      }
    },
    SET_GROUPED_ITEMS(state, data) {
      state.grouped_items = data;
    },
    UPDATE_GROUPED_ITEMS(state, data) {
      data.forEach((item) => {
        const index = state.grouped_items.findIndex(({ id }) => item.id === id);

        state.grouped_items.splice(index, 1, item);
      });
    },
  },
};
