const CURRENCY_SYMBOLS_MAP = {
  KZT: '₸',
  RUB: '₽',
};

export const getSymbolFromCurrency = (currency) => {
  if (!currency) return 'N/A';
  if (!(currency in CURRENCY_SYMBOLS_MAP)) return currency;

  return CURRENCY_SYMBOLS_MAP[currency];
};
