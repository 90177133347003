export default (store) => async (to, from, next) => {
  const redirectTo = to.matched.some((record) => record.meta.redirect);
  const isPublic = to.matched.some((record) => record.meta.public);
  const isAuth = to.matched.some((record) => record.meta.action === 'auth');
  const isLoggedIn = store.getters['auth/isLoggedIn'];

  if (isAuth && isLoggedIn) return next({ path: '/' });
  if (isPublic) return next();
  if (!isLoggedIn && redirectTo) return next({ path: to.meta.redirect });
  if (!isLoggedIn) return next({ path: '/signin' });

  return next();
};
