export default {
  loadScript() {
    if (process.env.NODE_ENV !== 'production') return;

    window.ChatraSetup = { startHidden: false };

    /* eslint-disable-next-line */
    (function(d, w, c) {w.ChatraID = 'Wn4hzEfN3Q2nhZmSm';var s = d.createElement('script');w[c] = w[c] || function() {(w[c].q = w[c].q || []).push(arguments);};s.async = true;s.src = 'https://call.chatra.io/chatra.js';if (d.head) d.head.appendChild(s);})(document, window, 'Chatra');
  },
  setChatraUser(user) {
    if (!window.Chatra || process.env.NODE_ENV !== 'production') return;

    window.Chatra('updateIntegrationData', {
      id: user.id,
      name: user.name,
      email: user.email,
    });
  },
};
