import Vue from 'vue';

export default ({ store }) => {
  Vue.prototype.$notify = {
    showMessage({
      message = '',
      color = '',
    }) {
      store.commit(
        'notify/SHOW_MESSAGE',
        { message, color },
        { root: true },
      );
    },
    showErrorMessage(response) {
      let message = `${response.status}: `;

      if (response.status >= 500) message += 'Что-то пошло не так, напишите программистам';
      else if (response.status === 401) message += 'Ошибка авторизации';
      else if (Array.isArray(response.data)) message += response.data[0];
      else if (typeof response.data === 'object' && response.data !== null) Object.entries(response.data).forEach(([key, value]) => { message += `${key}: ${value[0]} `; });

      store.commit(
        'notify/SHOW_MESSAGE',
        { message, color: 'error' },
        { root: true },
      );
    },
  };
};
