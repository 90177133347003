import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    warehouse: null,
    warehouseItems: [],
    warehouseItems_count: null,
    warehouses: {},
    warehouses_count: null,
    selectedWarehouseItems: [],
  },
  getters: {
    getSelectedItemIndex: (state, getters, rootState) => {
      const currentProductId = rootState.inventory_items.product?.id;
      return state.warehouseItems.findIndex((item) => item.inventory_item?.id === currentProductId);
    },
    getNextWarehouseItem: (state, getters) => {
      if (getters.getSelectedItemIndex + 1 < state.warehouseItems.length) return state.warehouseItems[getters.getSelectedItemIndex + 1];
      return null;
    },
    getSelectedWarehouseItemsIds: (state) => state.selectedWarehouseItems.map((item) => item.id),
  },
  actions: {
    async FETCH_WAREHOUSES({ commit }, params) {
      const { data } = await this.$http.get('/v1/warehouse/warehouses/', { params });
      commit('SET_WAREHOUSES', data.results);
      commit('SET_WAREHOUSES_COUNT', data.count);
    },
    async FETCH_WAREHOUSE({ commit }, id) {
      const { data } = await this.$http.get(`/v1/warehouse/warehouses/${id}/`);
      commit('SET_WAREHOUSE', data);
    },
    async FETCH_ITEMS({ commit }, { id, params }) {
      const { data } = await this.$http.get(`/v1/warehouse/warehouses/${id}/items/`, { params, withCancelToken: true });
      commit('SET_ITEMS', data.results);
      commit('SET_ITEMS_COUNT', data.count);
    },
    async UPDATE_ITEM({}, { itemId, params }) {
      await this.$http.put(`/v1/warehouse/items/${itemId}/`, params);
    },
  },
  mutations: {
    SET_WAREHOUSES(state, results) {
      state.warehouses = {};
      results.forEach((warehouse) => { Vue.set(state.warehouses, warehouse.id, warehouse); });
    },
    SET_WAREHOUSES_COUNT(state, count) {
      state.warehouses_count = count;
    },
    SET_WAREHOUSE(state, warehouse) {
      state.warehouse = warehouse;
    },
    SET_ITEMS(state, warehouseItems) {
      state.warehouseItems = warehouseItems;
    },
    SET_ITEMS_COUNT(state, count) {
      state.warehouseItems_count = count;
    },
    SET_SELECTED_WAREHOUSE_ITEMS(state, data) {
      state.selectedWarehouseItems = data;
    },
    TOGGLE_SELECTED_WAREHOUSE_ITEMS(state, item) {
      const itemExistsInList = state.selectedWarehouseItems.find((i) => i.id === item.id);

      if (itemExistsInList) {
        state.selectedWarehouseItems = state.selectedWarehouseItems.filter((i) => i.id !== item.id);
      } else {
        state.selectedWarehouseItems = [...state.selectedWarehouseItems, item];
      }
    },
  },
};
